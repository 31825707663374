<template>
  <div
    v-if="businessRef.total_balance !== undefined"
    id="business-account-balance"
    class="row"
  >
    <div class="col-12 font-17 mt-5 pb-0 font-weight-strong">
      Account Balance
    </div>
    <div class="col-12 pt-0">
      <span class="font-28 font-weight-strong">${{ withCommas(businessCreditsBalance) }}</span>
      is your current balance.<br>
      This balance will be used for future charges before your selected payment method is charged.
    </div>
    <!--    <div class="col-12 py-2">-->
    <!--      <ElButton-->
    <!--        :disabled="!isOwner || businessCreditsBalance >= MaxCreditBalance"-->
    <!--        size="medium"-->
    <!--        @click="$emit('openAddCreditDialog')"-->
    <!--      >-->
    <!--        Add funds-->
    <!--      </ElButton>-->
    <!--    </div>-->
    <!--    <div class="col-9 mt-3">-->
    <!--      <span class="font-17 font-weight-strong">Auto recharge</span>-->
    <!--    </div>-->
    <!--    <div class="col-3 recharge-switch">-->
    <!--      <ElSwitch-->
    <!--        v-model="rechargeCreditsEnabled"-->
    <!--        :disabled="!isOwner"-->
    <!--        :width="44"-->
    <!--        class="col-auto d-block"-->
    <!--        @change="rechargeCreditsEnabledChanged"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div class="col-9 pt-1 m-0 pb-2">-->
    <!--      Automatically top up your account balance when it falls below a specific threshold.-->
    <!--    </div>-->
    <!--    <template v-if="rechargeCreditsEnabled">-->
    <!--      <div-->
    <!--        v-if="editingRechargeCredits"-->
    <!--        class="col-12 py-3 px-4 ml-0 pl-0 mr-3"-->
    <!--      >-->
    <!--        Your payment method will be automatically recharged if the balance falls below-->
    <!--        ${{ CreditRechargeThreshold }}.-->
    <!--        <div class="pt-4 pb-4 pl-0">-->
    <!--          Future charges for Showdigs services will use this balance first.-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="businessCreditsBalance < CreditRechargeThreshold"-->
    <!--          class="row align-items-center justify-content-center pt-4 pb-3"-->
    <!--        >-->
    <!--          <ElAlert-->
    <!--            type="warning"-->
    <!--            show-icon-->
    <!--            :closable="false"-->
    <!--            class="p-2 m-3 col-6"-->
    <!--            tag-class="tagline-warning-light"-->
    <!--          >-->
    <!--            <div>-->
    <!--              Your balance is currently under ${{ CreditRechargeThreshold }}.-->
    <!--              By enabling auto recharge, your card will be immediately charged for-->
    <!--              the entered recharge amount.-->
    <!--            </div>-->
    <!--          </ElAlert>-->
    <!--        </div>-->
    <!--        <div class="row pt-4">-->
    <!--          <div class="col-6 mt-0 pt-0">-->
    <!--            <SdFloatLabel label="Recharge amount">-->
    <!--              <ElSelect-->
    <!--                v-model="uiFlags.rechargeAmount"-->
    <!--                placeholder="Recharge amount"-->
    <!--              >-->
    <!--                <ElOption-->
    <!--                  v-for="option in CreditRechargeAmounts"-->
    <!--                  :key="option.value"-->
    <!--                  :label="option.label"-->
    <!--                  :value="option.value"-->
    <!--                />-->
    <!--              </ElSelect>-->
    <!--            </SdFloatLabel>-->
    <!--          </div>-->
    <!--          <div class="payment-method col-6 pt-0">-->
    <!--            <SdPaymentMethodForm :editable="false" />-->
    <!--          </div>-->
    <!--          <div class="col-6 pt-2">-->
    <!--            <ElButton-->
    <!--              :class="$viewport.lt.md ? 'w-100 ml-0' :''"-->
    <!--              class="w-100 mt-4"-->
    <!--              @click="handleCancelEdit"-->
    <!--            >-->
    <!--              Cancel-->
    <!--            </ElButton>-->
    <!--          </div>-->
    <!--          <div class="col-6 pt-2">-->
    <!--            <ElButton-->
    <!--              :class="$viewport.lt.md ? 'w-100 ml-0' :''"-->
    <!--              :disabled="!uiFlags.rechargeAmount"-->
    <!--              :loading="uiFlags.loading"-->
    <!--              class="w-100 mt-4"-->
    <!--              type="primary"-->
    <!--              @click="saveRechargeAmount"-->
    <!--            >-->
    <!--              Save-->
    <!--            </ElButton>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div-->
    <!--        v-else-if="uiFlags.rechargeAmount"-->
    <!--        class="col-12 py-0"-->
    <!--      >-->
    <!--        <div class="col-3 p-0 my-0">-->
    <!--          <ElButton-->
    <!--            :class="$viewport.lt.md ? 'w-100 ml-0' :''"-->
    <!--            class="my-0"-->
    <!--            size="medium"-->
    <!--            @click="editingRechargeCredits = true"-->
    <!--          >-->
    <!--            Edit Amount-->
    <!--          </ElButton>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
  </div>
</template>

<script>
import { reactive, ref, computed } from '@vue/composition-api';
import { CreditRechargeAmounts, CreditRechargeThreshold, MaxCreditBalance } from '@/constants/Billing';
import { withCommas } from '@/utils/NumberUtil';
import Role from '@/constants/Role';
// import SdPaymentMethodForm from '@/components/common/form/SdPaymentMethodForm';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
// import SdFloatLabel from '../common/form/SdFloatLabel';

export default {
  name: 'BusinessAccountBalance',
  components: {
    // SdFloatLabel,
    // SdPaymentMethodForm,
  },
  setup(props, context) {
    const store = context.root.$store;
    const authUser = store.state.Auth.user;
    const businessCreditsBalance = computed(() => store.getters['Business/businessCreditsBalance']);
    const dispatch = store.dispatch;
    const uiFlags = reactive({
      rechargeAmount: null,
      loading: false,
    });
    const businessRef = ref({});
    const rechargeCreditsEnabled = ref(false);
    const editingRechargeCredits = ref(false);
    const rechargeAmountSaved = ref(null);
    const isOwner = authUser.role === Role.ACCOUNT_OWNER;

    init();

    return {
      uiFlags,
      businessRef,
      businessCreditsBalance,
      withCommas,
      isOwner,
      rechargeCreditsEnabled,
      CreditRechargeAmounts,
      saveRechargeAmount,
      rechargeCreditsEnabledChanged,
      CreditRechargeThreshold,
      MaxCreditBalance,
      editingRechargeCredits,
      handleCancelEdit,
      rechargeAmountSaved,
    };

    async function init() {
      businessRef.value = await dispatch('Business/get');
      uiFlags.rechargeAmount = businessRef.value.recurring_credits_amount;
      rechargeCreditsEnabled.value = !!uiFlags.rechargeAmount;
      rechargeAmountSaved.value = uiFlags.rechargeAmount;
      editingRechargeCredits.value = !rechargeCreditsEnabled.value;
    }
    async function saveRechargeAmount() {
      uiFlags.loading = true;
      try {
        await context.root.$store.dispatch('Billing/saveRechargeAmount', uiFlags.rechargeAmount);
        let message = 'Auto recharge has been disabled';
        if (uiFlags.rechargeAmount) {
          message = 'Recharge amount has been saved';
          if (businessCreditsBalance.value < CreditRechargeThreshold) {
            message += ', business has been charged and credits added successfully';
            const updatedBusinessCreditsBalance = await context.root.$store.dispatch('Billing/addBusinessCredits', uiFlags.rechargeAmount);
            context.root.$store.commit('Business/setBusinessCreditsBalance', updatedBusinessCreditsBalance);
          }
        }
        editingRechargeCredits.value = false;
        rechargeAmountSaved.value = uiFlags.rechargeAmount;
        showSuccessNotify(context, message);
      } catch (error) {
        showErrorNotify(context, error.message || 'Something went wrong');
      }
      uiFlags.loading = false;
    }

    async function rechargeCreditsEnabledChanged() {
      if (!rechargeCreditsEnabled.value) {
        uiFlags.rechargeAmount = null;
        editingRechargeCredits.value = false;
        await saveRechargeAmount();
      } else {
        editingRechargeCredits.value = true;
      }
    }

    function edit() {
      editingRechargeCredits.value = true;
    }

    function handleCancelEdit() {
      if (editingRechargeCredits.value) {
        editingRechargeCredits.value = false;
        if (!uiFlags.rechargeAmount || !rechargeAmountSaved.value) {
          rechargeCreditsEnabled.value = false;
          uiFlags.rechargeAmount = null;
        } else {
          uiFlags.rechargeAmount = rechargeAmountSaved.value;
        }
      }
    }
  },
};
</script>

<style lang="scss">
#business-account-balance {
  .payment-method {
    border-radius: $app-border-radius;
  }

  #payment-method-exists {
    min-height: 56px;
  }

  .recharge-switch {
    padding-top: 40px;
  }
}
</style>
